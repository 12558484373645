<template>
  <div class="flex flex-col share py-20 justify-center">
    <div class="text-xl -mt-8 mb-8">share</div>
    <div class="flex flex-row justify-center space-x-6">
      <a class="flex w-auto h-auto" target="_blank" :href="shareFacebook">
        <img src="@/assets/share-icon/facebook.svg" />
      </a>
      <a class="flex w-auto h-auto" target="_blank" :href="shareTwitter">
        <img src="@/assets/share-icon/twitter.svg" />
      </a>
      <a class="flex w-auto h-auto" target="_blank" :href="shareLine">
        <img src="@/assets/share-icon/line.svg" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareItems',
  computed: {
    shareFacebook() {
      return `https://www.facebook.com/sharer.php?u=${window.location}`
    },
    shareTwitter() {
      return `https://twitter.com/share?url=${window.location}`
    },
    shareLine() {
      return `https://line.me/R/share?text=${window.location}`
    }
  },
};
</script>

<style scoped>
.share {
  font-family: "Saol Text" !important;
  color: #994e2e;
  background: rgba(221, 218, 206, 1);
}
</style>
