<template>
  <div class="w-screen overflow-hidden">
    <div class="mx-4 lg:mx-4">
      <Carousel class="w-full h-full" :wrapAround="false" :breakpoints="breakpoints">
        <Slide class="h-full" v-for="(image, index) in images" :key="index">
          <div class="px-0 lg:px-4 w-full">
            <img
              :src="image.image_url"
              class="object-cover w-full h-120"
              alt="Wild Landscape"
            />
          </div>
        </Slide>

        <template #addons>
          <navigation
          >
            <template #next>
              <div class="pr-10">
                <chevron-right-icon
                fill-color="#F0EFEA"
                :size="64"
                />
              </div>
            </template>
            <template #prev>
              <div class="pl-10">
                <chevron-left-icon
                  fill-color="#F0EFEA"
                  :size="64"
                />
              </div>
            </template>
          </navigation>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";

export default {
  name: "GallerySlide",
  components: {
    Carousel,
    Slide,
    Navigation,
    ChevronLeftIcon,
    ChevronRightIcon
  },
  data: () => ({
    displayNavigation: false,
  }),
  updated() {
    this.onResize()
  },
  computed: {
    breakpoints() {
      return {
        0: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        1024: {
          itemsToShow: Math.min(3, this.images.length),
          snapAlign: 'start',
        },
      }
    },
  },
  props: {
    images: {
      type: [Array],
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize()
  },
  methods: {
    onResize() {
      this.displayNavigation = (window.innerWidth < 1024 && this.images.length > 1) || this.images.length > 3
    }
  }
};
</script>

<style>
.gallery-list-item {
  padding-left: 20px;
  padding-right: 20px;
} 

.gallery-list-container {
  margin-left: 20px;
  margin-right: 20px;
}

.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
</style>
