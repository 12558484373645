<template>
  <div class="markdown-content text-np-dark">
    <Markdown :source="source" :typographer="true" :html="true" />
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";

export default {
  name: "ContentBody",
  components: {
    Markdown,
  },
  props: {
    source: {
      type: [String],
    },
  },
};
</script>

<style>
.markdown-content {
  font-family: "Saol Text" !important;
}
.markdown-content a {
  word-break: break-all;
}
.markdown-content h1 {
  font-size: 3rem;
  line-height: 1;
}
.markdown-content h2 {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}
.markdown-content h3 {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}
.markdown-content h4 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
.markdown-content h5 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
.markdown-content h6 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
.markdown-content ul {
  list-style-type: disc;
  list-style-position: inside;
}
.markdown-content ol {
  list-style-type: decimal;
  list-style-position: inside;
}
</style>
